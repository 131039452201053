import React from 'react'
import PropTypes from 'prop-types'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import queryString from 'query-string'
import ActivateTicket from './pages/ActivateTicket/index.js'
import Books from './pages/Books/index.js'
import Layout from './layout'
import LtiAuthentication from './pages/integrations/Lti/index'
import JwtAuthentication from './pages/integrations/Jwt/index'
import MarcAuthentication from './pages/integrations/Marc21'
import Reader from './components/Reader'
import PdfReader from './components/PdfReader'
import Unavailable from './pages/Book/Unavailable'
import { isAuthenticated, redirectToLogin, setSessionToken } from './auth'
import { BooksProvider } from './contexts/BooksContext'
import BookDetails from './components/BookDetails'
import { BookDetailsProvider } from './contexts/BookDetailsContext'
import services from './services'
import Periodics from './pages/Periodics/index.js'
import Favorites from './pages/Books/Favorites'
import Categories from './pages/Books/Categories'
import CategoryMenu from './pages/CategoryMenu'
import ErrorPage from './pages/ErrorPage/index.js'
import BookError from './pages/BookError/index.js'
import MaintenancePage from './pages/MaintenancePage/index.js'
import { PenaltyProvider } from './contexts/PenaltyContext'
import CaptchaOverlay from './components/CaptchaOverlay'
import SearchResults from './pages/SearchResults'

const isInMaintenance = false

const PrivateRoute = ({
  component: Component,
  defaultLayout,
  ...routeParams
}) => {
  const searchValues = queryString.parse(routeParams.location.search)
  if (!localStorage.getItem('sessionToken') && searchValues.sessionToken) {
    services.Trackings.login()
  }

  if (searchValues.sessionToken) {
    setSessionToken(searchValues.sessionToken)
  }

  return (
    <Route
      {...routeParams}
      render={(props) => {
        if (isAuthenticated() && defaultLayout) {
          return (
            <Layout {...props}>
              <Component {...props} />
            </Layout>
          )
        }
        if (isAuthenticated()) {
          return <Component {...props} />
        }
        redirectToLogin()
        return null
      }}
    />
  )
}

export default function Routes() {
  if (isInMaintenance) {
    return (
      <Router>
        <Switch>
          <PrivateRoute
            path="/maintenance"
            component={() => (
              <Layout>
                <MaintenancePage />
              </Layout>
            )}
          />
          <Route path="/*">
            <Redirect to="/maintenance" />
          </Route>
        </Switch>
      </Router>
    )
  }

  return (
    <Router>
      <PenaltyProvider>
        <Switch>
          <PrivateRoute
            exact
            path="/"
            component={(routeProps) => (
              <BooksProvider {...routeProps}>
                <Layout>
                  <BookDetailsProvider>
                    <Books {...routeProps} />
                    <BookDetails />
                  </BookDetailsProvider>
                </Layout>
              </BooksProvider>
            )}
          />
          <PrivateRoute
            exact
            path="/periodics"
            component={() => (
              <Layout>
                <Periodics />
              </Layout>
            )}
          />
          <PrivateRoute
            exact
            path="/books"
            component={(routeProps) => (
              <BooksProvider {...routeProps}>
                <BookDetailsProvider>
                  <Books {...routeProps} />
                  <BookDetails />
                </BookDetailsProvider>
              </BooksProvider>
            )}
            defaultLayout
          />
          <PrivateRoute
            exact
            path="/favorites"
            component={(routeProps) => (
              <BooksProvider {...routeProps}>
                <Layout>
                  <BookDetailsProvider>
                    <Favorites {...routeProps} />
                    <BookDetails />
                  </BookDetailsProvider>
                </Layout>
              </BooksProvider>
            )}
          />
          <PrivateRoute
            exact
            path="/categories/:category/:subcategory?"
            component={(routeProps) => (
              <BooksProvider {...routeProps}>
                <BookDetailsProvider>
                  <Categories {...routeProps} />
                  <BookDetails />
                </BookDetailsProvider>
              </BooksProvider>
            )}
          />
          <PrivateRoute
            exact
            path="/categories"
            component={(routeProps) => (
              <BooksProvider {...routeProps}>
                <BookDetailsProvider>
                  <CategoryMenu {...routeProps} />
                  <BookDetails />
                </BookDetailsProvider>
              </BooksProvider>
            )}
          />
          <PrivateRoute
            exact
            path="/books/unavailable"
            component={Unavailable}
            defaultLayout
          />
          <PrivateRoute exact path="/epub/:uuid" component={Reader} />
          <PrivateRoute exact path="/pdf/:uuid" component={PdfReader} />
          <PrivateRoute
            exact
            path="/tickets/activate"
            component={(routeProps) => {
              if (services.Users.getIntegrationType() !== 'email_password') {
                return <Redirect to="/" />
              }
              return <ActivateTicket {...routeProps} />
            }}
            defaultLayout
          />
          <PrivateRoute
            path="/bad_request"
            component={(routeProps) => <ErrorPage {...routeProps} />}
            defaultLayout
          />
          <Route path="/lti" component={() => <LtiAuthentication />} />
          <Route
            path="/jwt"
            component={(routeProps) => <JwtAuthentication {...routeProps} />}
          />
          <Route
            path="/marc/:libraryCode"
            component={() => (
              <Layout>
                <MarcAuthentication />
              </Layout>
            )}
          />
          <Route
            path="/maintenance"
            component={() => (
              <Layout>
                <MaintenancePage />
              </Layout>
            )}
          />
          <Route
            path="/BookError"
            component={() => (
              <Layout>
                <BookError />
              </Layout>
            )}
          />
          <Route
            path="/results"
            component={() => (
              <Layout>
                <BookDetailsProvider>
                  <SearchResults />
                  <BookDetails />
                </BookDetailsProvider>
              </Layout>
            )}
          />
          <Route
            path="*"
            component={(routeProps) => (
              <Layout>
                <ErrorPage {...routeProps} />
              </Layout>
            )}
            defaultLayout
          />
        </Switch>
        <CaptchaOverlay />
      </PenaltyProvider>
    </Router>
  )
}
PrivateRoute.propTypes = {
  defaultLayout: PropTypes.bool,
  component: PropTypes.func.isRequired
}
PrivateRoute.defaultProps = {
  defaultLayout: false
}
