import React, { useContext, useEffect, useState } from 'react'
import { Icon } from '@leme/shared-components'

import { BooksContext } from '../../contexts/BooksContext'
import services from '../../services'
import classes from './FavoriteButton.module.css'
import { TRACKING_EVENTS } from '../../constants/TrackingEvents'


export default function FavoriteButton({
  book,
  runAfterOnClick,
  isFavorite,
  variant = 'lg'
}) {
  const { unfavoriteBook, favoriteBook } = useContext(BooksContext)
  const [bookIsFavorite, setBookIsFavorite] = useState(isFavorite)

  useEffect(() => {
    setBookIsFavorite(isFavorite)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = async () => {
    const favoriteBooks = JSON.parse(localStorage.getItem('favorites')) || []

    if (isFavorite) {
      const newFavorites = favoriteBooks.filter(
        (item) => item.favoriteId !== book.uuid
      )
      localStorage.setItem('favorites', JSON.stringify(newFavorites))
      setBookIsFavorite(!bookIsFavorite)

      const id = favoriteBooks.find((item) => {
        return item.favoriteId === book.uuid
      })
      await unfavoriteBook(id.id)
      await services.Trackings.favoritesTracking(book, {
        event: TRACKING_EVENTS.UNFAVORITE_BOOK
      })
    } else {
      favoriteBooks.push({ favoriteId: book.uuid, id: book.id })
      localStorage.setItem('favorites', JSON.stringify(favoriteBooks))
      setBookIsFavorite(!bookIsFavorite)

      await favoriteBook(book.uuid)
      await services.Trackings.favoritesTracking(book, {
        event: TRACKING_EVENTS.FAVORITE_BOOK
      })
    }

    if (runAfterOnClick) {
      runAfterOnClick()
    }
  }

  return (
    <button
      className={classes.button}
      onClick={onClick}
      data-testid="favorite-button"
      data-variant={variant}
    >
      {bookIsFavorite ? (
        <Icon name={'favorite'} customCss={classes.heartIconFilled} />
      ) : (
        <Icon name={'favorite'} customCss={classes.heartIcon} />
      )}
    </button>
  )
}
