import React, { useEffect, useState } from 'react'
import { Button, Icon } from '@leme/shared-components'

function AboutBookButton({ handleClick }) {
  const [ active, setActive ] = useState(false);

  const startTimer = () => {
    setTimeout(() => {
      setActive(true);
    }, 500);
  }

  useEffect(startTimer, []);

  const handleTimedClick = () => {
    if (active) {
      handleClick()
    }
  }

  return (
    <Button onClick={handleTimedClick} type="button" variant="outline" leftIcon={<Icon name={"info"} />}>Sobre o livro</Button>
  )
}

export default AboutBookButton;
