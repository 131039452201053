import React from 'react'
import ReactDOM from 'react-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { Provider } from '@leme/shared-components'
import '@leme/default-css'
import 'sanitize.css'
import Routes from './routes'
import MenuProvider from './contexts/MenuContext'
import { LibraryContextProvider } from './contexts/LibraryContext'
import packageInfo from '../package.json'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from './config/queryClient'

import '@leme/default-css'
import '@leme/shared-components/style.css'
import 'sanitize.css'

const { REACT_APP_BUGSNAG_API_KEY } = process.env

const getBugsnagAppVersion = () => {
  const monthYear = new Date().toISOString().slice(0, 7);
  const { version } = packageInfo

  if (monthYear === "2025-03") {
    return version
  }

  const majorVersion = version.split('.')[0];
  const bugSnagVersion = `v${majorVersion}--${monthYear}`

  return bugSnagVersion
}

Bugsnag.start({
  apiKey: REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  appVersion: getBugsnagAppVersion()
})

BugsnagPerformance.start({ apiKey: REACT_APP_BUGSNAG_API_KEY })

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ErrorBoundary>
      <React.StrictMode>
        <LibraryContextProvider>
          <MenuProvider>
            <Provider>
              <Routes />
            </Provider>
          </MenuProvider>
        </LibraryContextProvider>
      </React.StrictMode>
    </ErrorBoundary>
  </QueryClientProvider>,
  document.getElementById('root')
)
