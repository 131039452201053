import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Pagination } from '@mantine/core'
import { BlankSlate, Button, Media } from '@leme/shared-components'

import { BooksContext, BooksProvider } from '../../contexts/BooksContext'
import { useBookDetails } from '../../contexts/BookDetailsContext'
import { capitalize, setScrollPostion, bookUrl } from '../../utils'

import OpenBookButton from '../../components/OpenBookButton'
import AboutBookButton from '../../components/AboutBookButton'
import Loading from '../../components/Loading/index.js'

import classes from './SearchResults.module.css'

const AuthorName = ({ author }) => {
  const [maybeAuthorNameForReference, authorName] = author.split(', ')

  if (authorName) {
    return capitalize(authorName)
  }

  return capitalize(maybeAuthorNameForReference)
}

const SearchResults = () => {
  const history = useHistory()
  const { setBook: setSelectionBook } = useBookDetails()

  const { data, loading, fetchBooks } = useContext(BooksContext)

  const onClickDetails = (book) => {
    setSelectionBook(book)
  }

  const clearSearch = () => {
    history.goBack()
  }

  useEffect(() => {
    fetchBooks()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return <Loading />
  }

  if (data.books.length === 0) {
    return (
      <BlankSlate
        title="Nenhum resultado foi encontrado"
        description="Tente buscar por outro título, autor, ISBN ou assunto."
        renderAction={() => (
          <Button type="button" variant="outline" onClick={clearSearch}>
            Limpar pesquisa
          </Button>
        )}
      />
    )
  }

  return (
    <BooksProvider>
      <div
        style={{
          maxWidth: 930,
          margin: '0 auto'
        }}
      >
        <h1 className={classes.heading}>
          Encontramos <span>{data.total}</span> livros
        </h1>

        {data.books?.map((book) => (
          <>
            <div className={classes.desktopCard}>
              <Media
                key={book.isbn}
                title={book.titleCover}
                badges={[book.category, book.subcategory, book.subarea]}
                imageUrl={book.imageUrl}
                renderActions={() => (
                  <>
                    <OpenBookButton book={book} />
                    <AboutBookButton handleClick={() => onClickDetails(book)} />
                  </>
                )}
              >
                <p>
                  <b>Autores(as)</b>{' '}
                  {book?.authors.map((author) => (
                    <AuthorName key={author} author={author} />
                  ))}
                </p>
                <p>
                  {book.edition}º edição | {book.editionYear}
                </p>
              </Media>
            </div>
            <div className={classes.mobileCard} onClick={() => onClickDetails(book)}>
              <Media
                key={book.isbn}
                title={book.titleCover}
                badges={[book.category, book.subcategory, book.subarea]}
                imageUrl={book.imageUrl}
                isMobile={true}
                bookUrl={bookUrl(book)}
              >
                <p
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {book?.authors.map((author) => (
                    <AuthorName key={author} author={author} />
                  ))}
                </p>
                <p style={{ fontWeight: 'bold' }}>
                  {book.edition}º edição | {book.editionYear}
                </p>
              </Media>
            </div>
          </>
        ))}

        {data.page_count > 1 && (
          <div className={classes.pagination}>
            <Pagination
              value={Number(data.page)}
              total={data.page_count}
              onChange={(newPage) => {
                fetchBooks(newPage)
                setScrollPostion(0, 0)
              }}
              position="center"
              align="center"
              size="lg"
            />
          </div>
        )}
      </div>
    </BooksProvider>
  )
}

export default SearchResults
