import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Icon } from '@leme/shared-components'
import { saveLastScrollPosition, bookUrl } from '../../utils'
import services from '../../services'

function OpenBookButton({ book, fullWidth = false }) {
  const [ active, setActive ] = useState(false);
  
  const startTimer = () => {
    setTimeout(() => {
      setActive(true);
    }, 500);
  }

  useEffect(startTimer, []);

  const handleTimedClick = () => {
    if (active) {
      openBook(book)
    }
  }

  const history = useHistory()

  const openBook = (book) => {
    saveLastScrollPosition()
    services.Trackings.bookDetails(book, {
      origin: 'Capa',
      viewMode: 'Busca',
      format: book.format
    })
    history.push(bookUrl(book))
  }

  return (
    <Button
      type="button"
      variant="filled"
      leftIcon={<Icon name={'auto_stories'} />}
      onClick={handleTimedClick}
      fullWidth={fullWidth}
    >
      Abrir o livro
    </Button>
  )
}

export default OpenBookButton
