/* eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, NavLink } from 'react-router-dom'
import { Menu, MenuItem } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Card } from '@minha-biblioteca/saraiva-ui'
import { useBookDetails } from '../../../contexts/BookDetailsContext'
import { TRACKING_EVENTS } from '../../../constants/TrackingEvents'
import services from '../../../services'
import useStyles from './styles'

import {
  toCamelCase,
  getEdition,
  getAuthors,
  bookUrl,
  getBookReaderUrl,
  saveLastScrollPosition
} from '../../../utils'
import {LibraryContext} from '../../../contexts/LibraryContext'


export default function BookGalleryMode({ book, favoriteClicked, favorites, hasFavorites, fluxo }) {
  const classes = useStyles()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState(null)
  const { setBook: setSelectedBook } = useBookDetails()
  const popoverOpened = window.Boolean(anchorEl)
  const isResponsible = useMediaQuery({ maxWidth: 768 })
  const [data, setData] = useState([])
  const { libraryCode } = useContext(LibraryContext)

  const handleOpenPopover = (evt) => {
        setAnchorEl(evt.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const onClickFavorite = (book, event, tracking) => {
    if(!hasFavorites) return
    favoriteClicked(book, event, tracking)
  }

  const isFavorite = (uuid) => {
    if(!hasFavorites) return
    const iterableArray = favorites
    return iterableArray.find((item) => {
      return item.id === uuid
    })
  }

  const onClickDetalhes = (book) => {
    saveLastScrollPosition()
    services.Trackings.bookDetails(book, {
      origin: 'Capa',
      viewMode: 'Dropdown Galeria',
      format: book.format
    })
    setSelectedBook(book)
  }


  const onClickEvent = (book) => {
    saveLastScrollPosition()

    const favorited = isFavorite(book.uuid)

    if(favorited) {
      services.Trackings.trackOpenFavorites(book, {
        event: 'acessou_livro_favoritos',
        fluxo: fluxo
      })
    }

    history.push(getBookReaderUrl(book))
  }

  return (
    <>
    <Card
      iconImage={<MoreVertIcon style={{ fontSize: 18, color: 'black' }} />}
      classNameIconButton={classes.icon}
      classNameImage={classes.img}
      imageHoverText={'DETALHES'}
      image={book.imageUrl}
      showIconButton
      size={isResponsible ? 'vsm' : 'vsm'}
      hasFavorite={!libraryCode && hasFavorites  }
      handleFavorite={() => onClickFavorite(book, "favorite", TRACKING_EVENTS.FAVORITE_BOOK)}
      handleUnfavorite={() => onClickFavorite(book, "unfavorite", TRACKING_EVENTS.UNFAVORITE_BOOK)}
      onFavorite= {handleOpenPopover}
      favorite={() => isFavorite(book.uuid)}
      onClick={() => onClickDetalhes(book)}
    >
      <NavLink
        className={classes.mainContent}
        onClick={() => {
          saveLastScrollPosition()
          services.Trackings.bookDetails(book, {
            origin: 'Capa',
            viewMode: 'Dropdown Galeria',
            format: book.format
          })
        }}
        to={bookUrl(book)}
      >
        <div className={classes.mainContent}>
          <div className={classes.titleContainer}>
            <h1 className={classes.title}>{toCamelCase(book.titleCover)}</h1>
          </div>

          <div className={classes.subtitleContainer}>
            <span className={classes.subtitle}>{getEdition(book)}</span>
          </div>

          <div className={classes.subtitleContainer}>
            <span className={classes.subtitle}>{getAuthors(book)}</span>
          </div>

          <div className={classes.subtitleContainer}>
            <span className={classes.subtitle}>{book.format}</span>
          </div>
        </div>
      </NavLink>
      <Menu
        id="long-menu"
        aria-label="Menu do Livro"
        className={classes.popoverContainer}
        anchorEl={anchorEl}
        keepMounted
        open={popoverOpened}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          style: {
            maxHeight: 200,
            width: '20ch',
            borderRadius: 4
          }
        }}
      >
        <MenuItem
          onClick={() => {
            onClickEvent(book)
              services.Trackings.trackOpenBook(book, {
              viewMode: 'Detalhes do Livro',
              format: book.format
            })
          }}
        >
          Ler livro
        </MenuItem>

        <MenuItem
          onClick={() => {
            saveLastScrollPosition()
            services.Trackings.bookDetails(book, {
              origin: 'Dropdown',
              viewMode: 'Galeria',
              format: book.format
            })
            history.push(bookUrl(book))
          }}
        >
          Detalhes
        </MenuItem>
      </Menu>
    </Card>
    </>
  )
}

BookGalleryMode.propTypes = {
  book: PropTypes.shape({
    titleCover: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    authors: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
}
