/* eslint-disable */
import { getSessionToken } from '../../auth'
import httpClient from '../../gateway/http_request'

const Books = {
  async listBooks(page = 1, query = '', perPage = 30) {
    let headers = { 'session-token': getSessionToken() }
    const path = `/contents/api/v1/books?page=${page}&per_page=${perPage}&query=${query}`

    headers = httpClient.buildHeader(headers)

    const response = await httpClient.get(path, null, headers)
    return response
  },

  async listOneBook(id) {
    let headers = { 'session-token': getSessionToken() }
    const path = `/contents/api/v1/books/${id}`

    headers = await httpClient.buildHeader(headers)

    const response =  httpClient.get(path, null, headers)
    return response
  },

  async listBooksAdvanced(page, filter, query, perPage = 32) {
    let headers = { 'session-token': getSessionToken() }
    const path = `/contents/api/v1/books`

    headers = httpClient.buildHeader(headers)

    const payload = {
      page,
      filter: filter.filter(item => item[1]),
      query,
      per_page: perPage,
    }

    const response = await httpClient.post(path, payload, headers)
    return response
  },

  async listMostViewedBooks(page, perPage = 32 ) {
    let headers = { 'session-token': getSessionToken() }
    const path = `/contents/api/v1/most_viewed_books?page=${page}&per_page=${perPage}`

    headers = httpClient.buildHeader(headers)

    const response = await httpClient.get(path, null, headers)
    return response
  },

  async getListBooks(page, perPage, uuidList) {
    let headers = { 'session-token': getSessionToken() }
    let path = `/contents/api/v1/get_library_by_ticket?page=${page}&per_page=${perPage}`

    if(uuidList){
      const tempPath = uuidList.map((uuid) => {
        return `&products_uuids[]=${uuid}`
      })
      path += tempPath.map((item) => item).join('')
    }

    headers = httpClient.buildHeader(headers)

    const response = await httpClient.get(path, null, headers)
    return response
  },

  async getBook(uuid) {
    let headers = { 'session-token': getSessionToken() }
    const path = `/contents/api/v1/books/${uuid}`

    headers = httpClient.buildHeader(headers)

    const response = await httpClient.get(path, null, headers)
    return response
  },
  async getLastBooks() {
    let headers = { 'session-token': getSessionToken() }
    const path = `/contents/api/v1/last_books`

    headers = httpClient.buildHeader(headers)

    const response = await httpClient.get(path, null, headers)
    return response
  }
}

export default Books
