import React from 'react'
import {
  Anchor,
  ActionIcon,
  Text,
  TextInput,
  Popover,
  Navbar,
  Container,
  Group,
  FocusTrap
} from '@mantine/core'
import { Button, BREAKPOINTS } from '@leme/shared-components'
import {
  IconSearch,
  IconHeart,
  IconUserCircle,
  IconX
} from '@tabler/icons-react'
import ICON_BROSWE_SVG from '../../assets/browse.svg'
import ICON_BOOK_MENU_SVG from '../../assets/book_menu.svg'
import { useAuthentication } from '../../hooks/useAuthentication'
import classes from './Header.module.css'
import Links from '../../constants/Links'
import { useSearch } from '../../contexts/SearchContext'
import { useMediaQuery } from '@mantine/hooks'
import LemeLogo from '../LemeLogo'

const LinkButton = ({ children, ...props }) => {
  return (
    <Button
      component="a"
      className={props.selected && 'selected'}
      variant="white"
      styles={() => ({
        root: {
          color: 'var(--leme-color-gray-6)',
          '&:hover': {
            backgroundColor: 'var(--leme-color-gray-1)'
          },
          '&:active': {
            color: 'var(--leme-color-gray-9)',
            backgroundColor: 'var(--leme-color-gray-1)'
          },
          '&.selected': {
            color: 'var(--leme-color-gray-9)'
          },
          '&:disabled, &[data-disabled]': {
            backgroundColor: 'var(--leme-color-gray-0)',
            color: 'var(--leme-color-gray-5)'
          }
        }
      })}
      {...props}
    >
      {children}
    </Button>
  )
}

export default function Header({ onSearch }) {
  const [logout] = useAuthentication()
  const {
    searchTerm,
    setSearchTerm,
    redirectToSearchResults,
    showMobileSearch,
    isSearchPage,
    toggleShowMobileSearch
  } = useSearch()
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.md})`)

  const handleClear = () => {
    setSearchTerm('')
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault()

    if (onSearch) {
      onSearch(searchTerm)
    } else if (searchTerm !== '') {
      redirectToSearchResults()
    }
  }

  const OVERLAY_ID = 'search-overlay'

  const handleOverlay = () => {
    toggleShowMobileSearch()

    const overlay = document.getElementById(OVERLAY_ID)
    if (overlay) {
      overlay.style.display = 'none'
    }
  }

  const navbarStyles = {
    backgroundColor: 'var(--leme-color-gray-0)',
    padding: 0,
    border: 'none',
    left: 0,
    right: 0,
    top: 0,
    bottom: 'unset',
    position: 'fixed',
    zIndex: 1300
  }

  const containerStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    maxWidth: '1440px',
    width: '100%',
    margin: '0 auto',
    padding: '16px 32px',
    justifyContent: 'space-between',
    position: 'relative'
  }

  const groupStyles = {
    display: { base: 'none', md: 'flex' },
    flexDirection: 'row',
    gap: '1rem',
    flexGrow: 1,
    maxWidth: '430px',
    width: '100%',
    height: '40px',
    border: 'none',
    flexWrap: 'nowrap'
  }

  const searchOverlayStyles = {
    position: 'absolute',
    top: '72px',
    left: 0,
    right: 0,
    width: '100vw',
    height: 'calc(100vh - 72px - 72px)',
    backgroundColor: '#000',
    opacity: 0.5
  }

  const pathname = window.location.pathname

  return (
    <Navbar height={72} sx={navbarStyles} id="leme-navbar">
      <Container sx={containerStyles}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          {(isDesktop || !showMobileSearch) && <LemeLogo />}
        </div>
        {isDesktop && (
          <Group sx={groupStyles}>
            <LinkButton
              href="/"
              selected={pathname === '/'}
              leftIcon={<img src={ICON_BROSWE_SVG} alt="" size={24} />}
            >
              Catálogo
            </LinkButton>
            <LinkButton
              selected={pathname === '/favorites'}
              href="/favorites"
              leftIcon={<IconHeart alt="" size={24} />}
            >
              Favoritos
            </LinkButton>
            <LinkButton
              selected={pathname === '/periodics'}
              href="/periodics"
              leftIcon={<img src={ICON_BOOK_MENU_SVG} alt="" size={24} />}
            >
              Periódicos
            </LinkButton>
          </Group>
        )}

        {(isDesktop || showMobileSearch) && (
          <form className={classes.searchForm} onSubmit={handleSearchSubmit}>
            <FocusTrap active={showMobileSearch}>
              <TextInput
                placeholder="Busque pelo nome do livro, autor, ISBN..."
                value={searchTerm}
                classNames={{
                  root: classes.searchRoot,
                  input: classes.searchInput,
                  icon: classes.searchIcon,
                  rightSection: classes.searchClearButton
                }}
                type="text"
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                }}
                icon={<IconSearch alt="" />}
                rightSection={
                  searchTerm ? (
                    <button
                      className={classes.clearButton}
                      onClick={handleClear}
                      type="button"
                    >
                      {!showMobileSearch && <span>Limpar</span>}
                      {showMobileSearch && <IconX size={20} alt="" />}
                    </button>
                  ) : null
                }
              />
            </FocusTrap>
          </form>
        )}

        {(isDesktop || !showMobileSearch) && (
          <div>
            <Popover
              sx={{
                dropdown: classes.dropdown
              }}
            >
              <Popover.Target>
                <ActionIcon
                  data-testid="user-profile"
                  size="lg"
                  sx={{
                    height: '2.5rem',
                    minHeight: '2.5rem',
                    minWidth: '2.5rem',
                    width: '2.5rem',
                    color: 'var(--leme-color-gray-6)',
                    borderRadius: 'var(--leme-rounded-md)',
                    '&:hover': {
                      backgroundColor: 'var(--leme-color-gray-1)'
                    },
                    '&:active': {
                      color: 'var(--leme-color-gray-9)',
                      backgroundColor: 'var(--leme-color-gray-1)'
                    },
                    '&:disabled, &[data-disabled]': {
                      backgroundColor: 'var(--leme-color-gray-0)',
                      color: 'var(--leme-color-gray-5)'
                    }
                  }}
                >
                  <IconUserCircle size={40} alt="Perfil do usuário" />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown
                sx={{
                  border: '1px solid var(--leme-color-gray-3)',
                  boxShadow: '2px 2px 24px 0 rgba(0, 0, 0, 0.08) !important',
                  padding: '8px !important',
                  borderRadius: 'var(--leme-rounded-md) !important',
                  width: '230px !important',
                  left: 'initial !important',
                  right: '0 !important'
                }}
              >
                <Anchor
                  href={Links.PAGE_ACTIVATE_CODE}
                  sx={{
                    padding: '8px 16px',
                    display: 'block',
                    '&:hover': {
                      textDecoration: 'none',
                      backgroundColor: 'var(--leme-color-gray-1)'
                    }
                  }}
                >
                  <Text
                    sx={{
                      color: 'var(--leme-color-gray-9)',
                      font: 'var(--leme-typography-body-small-medium)'
                    }}
                  >
                    Ativar código
                  </Text>
                </Anchor>

                <Anchor
                  href={Links.EXTERNAL_HELP_CENTER}
                  target="_blank"
                  sx={{
                    padding: '8px 16px',
                    display: 'block',
                    '&:hover': {
                      textDecoration: 'none',
                      backgroundColor: 'var(--leme-color-gray-1)'
                    }
                  }}
                >
                  <Text
                    sx={{
                      color: 'var(--leme-color-gray-9)',
                      font: 'var(--leme-typography-body-small-medium)'
                    }}
                  >
                    Ajuda
                  </Text>
                </Anchor>

                <Text
                  sx={{
                    color: 'var(--leme-color-gray-9)',
                    font: 'var(--leme-typography-body-small-medium)',
                    padding: '8px 16px',
                    display: 'block',
                    '&:hover': {
                      textDecoration: 'none',
                      cursor: 'pointer',
                      backgroundColor: 'var(--leme-color-gray-1)'
                    }
                  }}
                  onClick={() => logout()}
                >
                  Sair
                </Text>
              </Popover.Dropdown>
            </Popover>
          </div>
        )}
        {showMobileSearch && !isSearchPage() && (
          <div
            id={OVERLAY_ID}
            style={searchOverlayStyles}
            onClick={handleOverlay}
          ></div>
        )}
      </Container>
    </Navbar>
  )
}
