import React, { createContext, useState, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const SearchContext = createContext()

export const SearchProvider = ({ children }) => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const [showMobileSearch, setShowMobileSearch] = useState(false)
  const [searchTerm, setSearchTerm] = useState(searchParams.get('query') || '')

  const redirectToSearchResults = () => {
    if (searchTerm) {
      window.location.assign(`/results?query=${searchTerm.trim()}`)
      setSearchTerm('')
    }
  }

  const isSearchPage = () => {
    return window.location.pathname === '/results'
  }

  const toggleShowMobileSearchAtFirstRender = () => {
    if (window.innerWidth < 768 && isSearchPage()) {
      setShowMobileSearch(true)
    }
  }

  const toggleShowMobileSearch = () => {
    setShowMobileSearch((prevState) => !prevState)
  }

  useEffect(() => {
    toggleShowMobileSearchAtFirstRender()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        redirectToSearchResults,
        showMobileSearch,
        isSearchPage,
        toggleShowMobileSearch
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export const useSearch = () => useContext(SearchContext)
