import React from 'react'
import { Box, Text, Button } from '@mantine/core'
import { ProfileIcon } from './styles'

export default function CustomTooltip({ step, closeProps }) {
  return (
    <Box
      sx={() => ({
        padding: '2rem',
        borderRadius: 'var(--leme-rounded-xl)',
        backgroundColor: 'var(--leme-color-gray-0)',
        boxShadow: '2px 2px 24px 0px #00000008',
        maxWidth: 500,
        width: '100%',
        display: 'grid',
        gap: '1.5rem',
      })}
    >
      <header style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Text
          sx={{
            font: 'var(--leme-typography-body-large-semibold)',
            textAlign: 'left',
            color: 'var(--leme-color-gray-9)',
          }}
        >
          {step.title || 'Menu, pesquisa e perfil'}
        </Text>
        <Button
          variant="subtle"
          {...closeProps}
          data-testid="skip-button"
          title="Fechar"
          sx={{
            padding: 0,
            color: 'var(--leme-color-gray-9)',
            font: 'var(--leme-typography-body-small-semibold)',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          Fechar
        </Button>
      </header>

      <section style={{
        paddingBottom: '0.5rem',
      }}>
        <Text sx={{
          font: 'var(--leme-typography-body-small-regular)',
          color: 'var(--leme-color-gray-9)',
        }}>
          Use o menu para navegar pelo catálogo, ver sua lista de favoritos e
          conhecer nossos periódicos.
        </Text>
        <Text sx={{
          font: 'var(--leme-typography-body-small-regular)',
          color: 'var(--leme-color-gray-9)',
        }}>
          Pesquise pelo nome do livro, autor, ISBN ou palavra-chave para
          encontrar seu livro.
        </Text>

        <div style={{
          display: 'flex',
          gap: '0.5rem',
        }}>
          <Text sx={{
            font: 'var(--leme-typography-body-small-regular)',
            color: 'var(--leme-color-gray-9)',
          }}>Acesse seu perfil pelo ícone</Text>
          <ProfileIcon />
        </div>
      </section>
    </Box>
  )
}
