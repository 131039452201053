import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { CloseButton, ThemeIcon } from '@mantine/core'
import classes from './BookDetails.module.css'
import { useBookDetails } from '../../contexts/BookDetailsContext'
import { getAuthors, getEdition } from '../../utils'
import OpenBookButton from '../../components/OpenBookButton'
import {
  Button,
  Media,
  Tabs,
  createTabOption,
  Icon
} from '@leme/shared-components'
import FavoriteButton from '../../components/FavoriteButton'

function ReferenceWithCopy({ content }) {
  const handleCopy = () => {
    const text = content.replace(/<[^>]*>/g, '')
    navigator.clipboard.writeText(text)
  }

  return (
    <div className={classes.referencesContainer}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <Button
        variant="outline"
        size="compact-md"
        leftIcon={<Icon name={'content_copy'} />}
        onClick={handleCopy}
        styles={() => ({
          root: {
            height: '40px',
            width: '111px',
            font: 'var(--leme-typography-body-small-medium)'
          }
        })}
      >
        Copiar
      </Button>
    </div>
  )
}

const BookDetailsDialog = (props) => {
  const { bookDetailsRef, open, close, book } = useBookDetails()
  const [isFavorite, setIsFavorite] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const hasRunEffect = useRef(false)

  const handleFavorites = async () => {
    const favoriteBooks = JSON.parse(localStorage.getItem('favorites')) || []
    const favoriteBook = favoriteBooks.find(
      (item) => item.favoriteId === book?.uuid
    )
    setIsFavorite(!!favoriteBook)
  }

  useEffect(() => {
    if (hasRunEffect.current) return
    hasRunEffect.current = true
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576)
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (book) {
      handleFavorites()
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [book])

  if (!book) return <></>
  return (
    <dialog
      ref={bookDetailsRef}
      className={isMobile ? classes.dialogMobile : classes.dialog}
      onClick={(e) => {
        if (e.target === e.currentTarget) close()
      }}
    >
      <header
        className={isMobile ? classes.dialogHeaderMobile : classes.dialogHeader}
      >
        {isMobile && (
          <ThemeIcon
            variant="light"
            size="lg"
            onClick={close}
            style={{
              color: 'black',
              background: 'none',
              paddingRight: '2.5rem'
            }}
          >
            <Icon name={'arrow_back'} />
          </ThemeIcon>
        )}
        <h2>Sobre o livro</h2>
        {!isMobile && (
          <CloseButton
            onClick={close}
            aria-label="Fechar sobre o livro"
            size="lg"
            sx={{ color: 'var(--leme-color-gray-9)' }}
          />
        )}
      </header>

      <section className={classes.bookContainer}>
        <Media
          title={book.titleCover}
          badges={[book.category, book.subcategory, book.subarea]}
          imageUrl={book.imageUrl}
          isMobile={isMobile}
          renderActions={() =>
            !isMobile && (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '24px' }}
              >
                <OpenBookButton book={book} />
                <FavoriteButton
                  book={book}
                  isFavorite={isFavorite}
                  runAfterOnClick={handleFavorites}
                />
              </div>
            )
          }
        >
          <p>
            <b>Autores(as):</b> {getAuthors(book, { displayAll: true })}
          </p>
          <p>{getEdition(book)}</p>
        </Media>
      </section>

      <section className={classes.tabsContainer}>
        <Tabs
          defaultValue={'description'}
          options={[
            createTabOption(
              'description',
              'Descrição',
              <div dangerouslySetInnerHTML={{ __html: book.description }} />
            ),
            createTabOption(
              'reference',
              'Referência',
              <ReferenceWithCopy content={book.abnt} />
            )
          ]}
        />
      </section>
      {isMobile && (
        <section className={classes.mobileBottomActions}>
          <OpenBookButton book={book} fullWidth={true} />
        </section>
      )}
    </dialog>
  )
}

export default BookDetailsDialog

BookDetailsDialog.prototype = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bookUuid: PropTypes.string.isRequired
}
