import React, { createContext, useContext, useRef, useState } from 'react'

const BookDetailsContext = createContext()

export const BookDetailsProvider = ({ children }) => {
  const bookDetailsRef = useRef(null)
  const [book, setBook] = useState(null)

  const open = () => {
    if (bookDetailsRef.current) {
      bookDetailsRef.current.showModal()
    }
  }

  const close = () => {
    if (bookDetailsRef.current) {
      bookDetailsRef.current.close()
      setBook(null)
    }
  }

  return (
    <BookDetailsContext.Provider
      value={{ open, close, bookDetailsRef, book, setBook }}
    >
      {children}
    </BookDetailsContext.Provider>
  )
}

export const useBookDetails = () => useContext(BookDetailsContext)
