/* eslint-disable */
import React from 'react';
import { Container, Stack, Card, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import admPDF from '../../utils/periodics/administration.pdf';
import acsPDF from '../../utils/periodics/accounting_sciences.pdf';
import lawPDF from '../../utils/periodics/lawyer.pdf';

import admPNG from '../../assets/periodics/administration.png';
import acsPNG from '../../assets/periodics/accounting_sciences.png';
import lawPNG from '../../assets/periodics/lawyer.png';

import Trackings from '../../services/trackings/trackings';
import classes from './Periodics.module.css';

export default function Periodics() {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  const openArchive = (param, periodicName) => {
    window.open(param);
    Trackings.trackOpenPDF({
      card: periodicName,
    });
  };

  return (
    <Container size="xl">
      <Text align="left" size={isSmallScreen ? "sm" : "20px"} className={classes.description}>
        Para facilitar seus estudos, aqui está uma curadoria de diversos links de periódicos. 
        Neles, você vai encontrar artigos, resumos e resenhas publicados por e para a comunidade acadêmica.
      </Text>
    
      <Stack align="center" spacing="md">
        <Card onClick={() => openArchive(admPDF, 'Administração')} 
          style={{ backgroundImage: `url(${admPNG})` }} 
          className={classes.card}
        >
          <Text className={classes.cardText}>Administração</Text>
        </Card>
    
        <Card onClick={() => openArchive(acsPDF, 'Ciências Contábeis')} 
          style={{ backgroundImage: `url(${acsPNG})` }} 
          className={classes.card}
        >
          <Text className={classes.cardText}>Ciências Contábeis</Text>
        </Card>
    
        <Card onClick={() => openArchive(lawPDF, 'Direito')} 
          style={{ backgroundImage: `url(${lawPNG})` }} 
          className={classes.card}
        >
          <Text weight={600} className={classes.cardText}>Direito</Text>
        </Card>
      </Stack>
    </Container>
  );
}
