/* eslint-disable react/forbid-prop-types, react-hooks/exhaustive-deps */
/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Divider } from '@material-ui/core'

import NoResults from './NoResults/index.js'
import SnackBar from '../../components/SnackBar'
import Pagination from '../../components/Pagination/index.js'
import Loading from '../../components/Loading'
import { BooksContext } from '../../contexts/BooksContext'
import { useBookDetails } from '../../contexts/BookDetailsContext'
import BookCard from '../../components/BookCard'
import { getLastScrollPosition, setScrollPostion } from '../../utils'
import services from '../../services'

import classes from './Books.module.css'

function Books() {
  const {
    data,
    loading,
    fetchBooks,
    getFavoriteBooks,
    getLastBooks,
    lastBooks
  } = useContext(BooksContext)
  const { page, total: totalBooks, page_count: lastPage } = data

  const anchorRef = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [severity, setSeverity] = useState('success')
  const [showAlert, setShowAlert] = useState(false)
  const { setBook: setSelectionBook } = useBookDetails()
  const [alertMessage, setAlertMessage] = useState(
    'Livro adicionado aos favoritos'
  )
  const [isloadingLastBooks, setLoadingLastBooks] = useState(true)

  const lastScrollPosition = getLastScrollPosition()

  const onClickDetails = (book) => {
    setSelectionBook(book)
  }

  const onClickDetailsLastBooks = (book) => {
    services.Trackings.lastBookDetails(book, {
      origin: 'Capa',
      viewMode: 'Abertos Recentemente',
      format: book.format
    })
    setSelectionBook(book)
  }

  const showAlertMessage = (message, severity) => {
    setAlertMessage(message)
    setSeverity(severity)
    setShowAlert(true)
    setTimeout(async () => {
      setShowAlert(false)
    }, 800)
  }

  const renderBooksCountLabel = () => {
    const totalBooksOnPage = data.books.length

    if (page === lastPage) {
      return `${totalBooks - totalBooksOnPage + 1}-${totalBooks}`
    }
    return `${(page - 1) * totalBooksOnPage + 1}-${page * totalBooksOnPage}`
  }

  async function handleFavorites() {
    setIsLoading(true)
    const data = await getFavoriteBooks(2)
    localStorage.setItem('favorites', JSON.stringify(data))
    setIsLoading(false)
  }

  useEffect(() => {
    fetchBooks()
    handleFavorites()
  }, [])

  useEffect(async () => {
    setLoadingLastBooks(true)
    await getLastBooks()
    if (page > 1 && !lastScrollPosition) {
      localStorage.setItem(
        'lastBooksPosition',
        anchorRef.current.offsetTop - 60
      )
      setScrollPostion(0, localStorage.getItem('lastBooksPosition'))
    }
    localStorage.removeItem('lastBooksPosition')
    setTimeout(() => setLoadingLastBooks(false), 400)
  }, [page])

  const renderBooks = () => {
    return (
      <div className={`${classes.books} ${classes.galleryBooks}`}>
        {data.books.map((book) => (
          <BookCard
            key={book.uuid}
            book={book}
            onClickDetails={() => onClickDetails(book)}
            showAlertMessage={showAlertMessage}
          />
        ))}
      </div>
    )
  }

  const renderPagination = () => {
    if (data.page_count === 1) {
      return null
    }

    return (
      <Pagination
        pageCount={data.page_count}
        page={Number(data.page)}
        fetchContents={fetchBooks}
      />
    )
  }

  if (loading || isLoading) return <Loading />
  if (!data.books) return null
  if (data.books.length === 0) return <NoResults />

  return (
    <>
      {showAlert && (
        <SnackBar
          show={false}
          severity={severity}
          variant="standart"
          noClose
          className={classes.alertMessage}
        >
          {alertMessage}
        </SnackBar>
      )}

      {lastBooks.length > 0 && (
        <>
          <h2 className={classes['recent-opened']}>Continue lendo</h2>
          <Divider className={classes.divider} />
          <div style={{ margin: '32px auto', display: 'flex' }}>
            <div
              style={{
                margin: '0 auto',
                display: 'flex',
                gap: 20,
                overflow: 'auto',
                paddingBottom: 20
              }}
            >
              {isloadingLastBooks && <Loading />}
              {!isloadingLastBooks &&
                lastBooks.map((book, index) => (
                  <div key={index}>
                    <BookCard
                      key={book.uuid}
                      book={book}
                      onClickDetails={() => onClickDetailsLastBooks(book)}
                      showAlertMessage={showAlertMessage}
                    />
                  </div>
                ))}
            </div>
          </div>
        </>
      )}

      <div className={classes.header}>
        <h2 className={classes['search-result-text']}>
          {`Exibindo ${renderBooksCountLabel()} de ${totalBooks} livros`}
        </h2>
      </div>
      <Divider />
      <span className={classes['list-by-label']}>
        Listado por: edições mais recentes
      </span>

      {renderBooks()}

      <Divider />

      <div className={classes.pagination}>{renderPagination()}</div>
    </>
  )
}

export default Books
