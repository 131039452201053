/* eslint-disable react/forbid-prop-types, consistent-return */
/* eslint-disable */
import React, {
  useCallback,
  useState,
  useEffect,
  createContext,
  useRef
} from 'react'
import queryString from 'query-string'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import GalleryIcon from '@material-ui/icons/ViewModule'
import ListIcon from '@material-ui/icons/ViewList'
import { useHistory } from 'react-router-dom'
import { useFavorite } from '../hooks/useFavorite'
import services from '../services'
import Users from '../services/accounts/users'
import Favorites from '../services/favorites/index'
import { setScrollPostion, getLastScrollPosition } from '../utils'
import {
  redirectToLogin,
  isUnauthorized,
  isInvalidTicket,
  setSessionToken,
  isBadRequest,
  isTimedOut
} from '../auth'
import * as _ from 'lodash'
import { usePenalty } from '../contexts/PenaltyContext'
import { PENALTY_LEVEL_CAPTCHA } from '../constants/PenaltyLevel'

const BooksContext = createContext()

function BooksProvider({ children }) {
  const history = useHistory()
  const { handlePenaltyLevel } = usePenalty()
  const [favoritesRequests] = useFavorite()

  const queryClient = useQueryClient()

  let defaultViewMode = { name: 'Galeria', icon: <GalleryIcon /> }

  const viewModeLocalStorage = window.localStorage.getItem('viewMode')

  if (viewModeLocalStorage) {
    defaultViewMode = {
      name: viewModeLocalStorage,
      icon: viewModeLocalStorage === 'Galeria' ? <GalleryIcon /> : <ListIcon />
    }
  }

  const [loading, setLoading] = useState(true)
  const [fetchingBooks, setFetchingBooks] = useState(false)
  const [lastBooks, setLastBooks] = useState([])
  const [recommendations, setRecommendations] = useState([])
  const [data, setData] = useState([])
  const [booksPerPage, setBooksPerPage] = useState(32)
  const [booksFormat, setBooksFormat] = useState('Todos')
  const [booksSortedBy, setBooksSortedBy] = useState('Edições mais recentes')
  const [booksPage, setBooksPage] = useState(1)
  const [isResponsive, setIsResponsive] = useState(false)
  const [categories, setCategories] = useState([])
  const [favorites, setFavorites] = useState({
    favoriteArray: [],
    favoriteObjectArray: [],
    completeInfo: [],
    completeData: []
  })
  const [fetchingFavorites, setFetchingFavorites] = useState(false)
  const favoritesLenght = useRef(0)
  const [isSearch, setIsSearch] = useState(false)

  const [viewMode, setViewMode] = useState(defaultViewMode)

  useEffect(() => {
    localStorage.setItem('viewMode', viewMode.name)
  }, [viewMode])

  const getFavoriteBooks = useCallback(async (id) => {
    const path = '/interactions/api/v1/favorites?favorite_type=uuid'
    const payload = {
      requestType: 'GET'
    }

    try {
      const response = await Favorites.favoritesRequests(path, payload)

      if (response.status === 403) {
        const responseText = await response.text()
        if (responseText === 'penalty_level_1') {
          handlePenaltyLevel(PENALTY_LEVEL_CAPTCHA)
          return
        }
      }

      let array = []
      let dataArray = []

      if (response && Array.isArray(response.data)) {
        response.data.forEach((item) => {
          array.push({ favoriteId: item.favorite_id, id: item.id })
          dataArray.push({ id: item.favorite_id })
        })

        const isInteger = response.data.length % 12 === 0
        let pages = Math.ceil(response.data.length / 12)

        setFavorites({
          favoriteArray: dataArray,
          favoriteObjectArray: array,
          completeInfo: response.data,
          pages: pages
        })
      } else {
        console.warn(
          'Dados de favoritos estão indefinidos ou não são um array.'
        )
      }

      if (id === 1) return dataArray
      if (id === 2) return array
    } catch (error) {
      console.error('Erro ao buscar favoritos:', error)
    }
  })

  async function auxParseFavorites(data) {
    const parsedData = await Promise.all(
      data?.map(async (favoriteBook) => {
        const response = await services.Books.listOneBook(favoriteBook.id)
        if (response.status === 200) {
          return await response.json()
        }
      })
    )

    const realFavorites = await Promise.all(
      parsedData?.filter((item) => {
        return item !== undefined
      })
    )
    favoritesLenght.current = realFavorites.length
    return realFavorites
  }

  async function parseFavorites(page) {
    const data_ = await getFavoriteBooks(1)

    if (!Array.isArray(data_)) {
      console.warn('Dados de favoritos estão indefinidos ou não são um array.')
      return
    }

    const uuidList = data_.map((item) => item.id)
    if (uuidList.length === 0) return

    const favorites_ = await services.Books.getListBooks(page, 12, uuidList)
    const response = await favorites_.json()

    const realItems = response.items[0]
    const realPage = Number(response.page)
    const { totalPages, totalElements } = response
    return { realItems, totalPages, totalElements, realPage }
  }

  async function fetchBooks(paginationPage = null) {
    const { page: queryPage, query: searchTerm } = queryString.parse(window.location.search);
    const page = paginationPage || parseInt(queryPage || '1', 10);
    
    const cacheKey = ['books', { page, query: searchTerm }];
    const cachedResult = queryClient.getQueryData(cacheKey);
    
    if (cachedResult) {
      setLoading(false);
      processResult(cachedResult, searchTerm);
      return cachedResult;
    }
    
    setLoading(true);
  
    try {
      const response = await services.Books.listBooks(page, searchTerm);
      
      const sessionToken = response.headers.get('session-token');
      if (sessionToken) setSessionToken(sessionToken);
      
      if (handleResponseStatus(response)) {
        setLoading(false);
        return null;
      }
      
      const result = await response.json();
      queryClient.setQueryData(cacheKey, result);
      
      processResult(result, searchTerm);
      return result;
    } catch (err) {
      console.error('Erro ao buscar livros:', err);
      setData([]);
    } finally {
      setLoading(false);
      setScrollPostion(0, getLastScrollPosition() || 0);
      window.localStorage.removeItem('lastScrollPosition');
    }
  }
  
  function processResult(result, searchTerm) {
    if (!result) {
      console.warn('Resultado vazio ao processar livros');
      setData([]);
      return;
    }
    
    window.localStorage.setItem('data', JSON.stringify(result));
  
    if (searchTerm) {
      services.Trackings.search({
        term: searchTerm,
        booksLength: result.total || 0,
      });
      setViewMode({ name: 'Lista', icon: <ListIcon /> });
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
  
    setData(result);
  }
  
  function handleResponseStatus(response) {
    if (!response) {
      console.error('Resposta indefinida');
      return true;
    }
  
    if (isUnauthorized(response.status)) {
      redirectToLogin();
      return true;
    }
  
    if (isInvalidTicket(response.status)) {
      history.push('/tickets/activate?no-access=true');
      return true;
    }
  
    if (isBadRequest(response.status) || isTimedOut(response.status)) {
      history.push('/bad_request');
      return true;
    }
  
    if (response.status === 403) {
      response.text().then(text => {
        if (text === 'penalty_level_1') {
          handlePenaltyLevel(PENALTY_LEVEL_CAPTCHA);
        }
      }).catch(err => console.error('Erro ao processar resposta 403:', err));
      return true;
    }
  
    if (!response.ok) {
      getErrors(response).then(errors => 
        console.error('Erro na resposta:', errors)
      ).catch(err => 
        console.error('Falha ao processar erros:', err)
      );
      return true;
    }
    
    return false;
  }

  const favoriteBook = async (uuid) => {
    const path = 'interactions/api/v1/favorites'
    const payload = {
      favorite_type: 'uuid',
      favorite_id: uuid,
      user_id: Users.getUserId(),
      requestType: 'POST'
    }
    const response = await favoritesRequests(path, payload)
    return response
  }
  const unfavoriteBook = async (uuid) => {
    const path = `interactions/api/v1/favorites/${uuid}`
    const payload = {
      requestType: 'DELETE'
    }

    const response = await favoritesRequests(path, payload)
    return response
  }

  async function fetchBooksFiltered() {
    const queryStrings = queryString.parse(window.location.search)
    const searchTerm = queryStrings.query
    const page = paginationPage || parseInt(queryStrings.page || '1', 10)
    setFetchingBooks(true)
    try {
      const response = await services.Books.listBooksAdvanced(
        page,
        [['format', booksFormat === 'Todos' ? null : booksFormat]],
        null,
        booksPerPage
      )
      setSessionToken(response.headers.get('session-token'))

      if (isUnauthorized(response.status)) {
        redirectToLogin()
      }

      if (isInvalidTicket(response.status)) {
        history.push('/tickets/activate?no-access=true')
      }

      if (response.status === 403) {
        const responseText = await response.text()
        if (responseText === 'penalty_level_1') {
          handlePenaltyLevel(PENALTY_LEVEL_CAPTCHA)
        }
      }

      if (!response?.ok) {
        let errors = await getErrors(response)
        throw errors
      }

      const result = await response.json()

      window.localStorage.setItem('data', JSON.stringify(result))

      if (searchTerm) {
        services.Trackings.search({
          term: searchTerm,
          booksLength: result.total
        })
        setViewMode({ name: 'Lista', icon: <ListIcon /> })
        setIsSearch(true)
      }

      setData(result)
      setIsSearch(false)
    } catch (err) {
      // eslint-disable-next-line
      console.error(err)
    } finally {
      setFetchingBooks(false)
    }
  }

  async function fetchCategories() {
    setLoading(true)
    try {
      const response = await services.Categories.listCategories()

      if (response.status === 403) {
        const responseText = await response.text()
        if (responseText === 'penalty_level_1') {
          handlePenaltyLevel(PENALTY_LEVEL_CAPTCHA)
        }
      }

      if (!response.ok) {
        const errors = await getErrors(response)
        throw new Error(errors)
      }

      const result = await response.json()
      const category = [
        ...new Map(
          result.map((item) => [
            item.category,
            { name: item.category, subcategories: [] }
          ])
        ).values()
      ]
      setCategories(category)
      setLoading(false)
      return category
    } catch (error) {
      setLoading(false)
      return []
    }
  }

  async function fetchMostViewedBooks() {
    setFetchingBooks(true)
    const queryStrings = queryString.parse(window.location.search)
    const page = paginationPage || parseInt(queryStrings.page || '1', 10)
    try {
      const response = await services.Books.listMostViewedBooks(
        page,
        booksPerPage
      )

      if (response.status === 403) {
        const responseText = await response.text()
        if (responseText === 'penalty_level_1') {
          handlePenaltyLevel(PENALTY_LEVEL_CAPTCHA)
        }
      }

      if (!response?.ok) {
        let errors = await getErrors(response)
        throw errors
      }

      const result = await response.json()

      setData(result)
    } catch (err) {
      // eslint-disable-next-line
      console.error(err)
    } finally {
      setFetchingBooks(false)
    }
  }

  const getPages = async () => {
    try {
      const response = await services.Pages.getPages()

      if (response.status === 403) {
        const responseText = await response.text()
        if (responseText === 'penalty_level_1') {
          handlePenaltyLevel(PENALTY_LEVEL_CAPTCHA)
        }
      }

      if (!response?.ok) {
        let errors = await getErrors(response)
        throw errors
      }
      return (await response.json()).data
    } catch (err) {
      // eslint-disable-next-line
      console.error(err)
    }
  }

  const getLastBooks = async () => {
    try {
      const response = await services.Books.getLastBooks()

      if (response.status === 403) {
        const responseText = await response.text()
        if (responseText === 'penalty_level_1') {
          handlePenaltyLevel(PENALTY_LEVEL_CAPTCHA)
        }
      }

      if (!response?.ok) {
        let errors = await getErrors(response)
        throw errors
      }
      const result = await response.json()

      const pages = await getPages()
      const lastBooksHash = result.reduce(
        (hashes, value) => ({
          ...hashes,
          [value.uuid]: value
        }),
        {}
      )
      const sortedLastBook = pages
        .map((page) => lastBooksHash[page.book_id])
        .filter(Boolean)
      setLastBooks(sortedLastBook)
    } catch (err) {
      // eslint-disable-next-line
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const getErrors = async (response) => {
    if (!response) {
      return 'No response received'
    }

    let errors
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.includes('application/json')) {
      errors = await response.json()
    } else {
      errors = await response.text()
    }
    return errors
  }

  return (
    <BooksContext.Provider
      value={{
        loading,
        setLoading,
        data,
        setData,
        viewMode,
        setViewMode,
        fetchBooks,
        lastBooks,
        getLastBooks,
        booksPerPage,
        recommendations,
        setRecommendations,
        setBooksPerPage,
        booksFormat,
        setBooksFormat,
        booksSortedBy,
        setBooksSortedBy,
        fetchBooksFiltered,
        fetchingBooks,
        setFetchingBooks,
        booksPage,
        setBooksPage,
        isResponsive,
        setIsResponsive,
        fetchMostViewedBooks,
        categories,
        setCategories,
        fetchCategories,
        favoriteBook,
        unfavoriteBook,
        favorites,
        getFavoriteBooks,
        fetchingFavorites,
        parseFavorites,
        isSearch
      }}
    >
      {children}
    </BooksContext.Provider>
  )
}

BooksProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { BooksContext, BooksProvider }
