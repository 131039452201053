import React, { useState, useEffect } from 'react'
import { Stack, AspectRatio, Image, Overlay, Text } from '@mantine/core'

import { toCamelCase, getEdition, getAuthors } from '../../utils'

import classes from './BookCard.module.css'
import FavoriteButton from '../FavoriteButton'

import OpenBookButton from '../../components/OpenBookButton'
import AboutBookButton from '../../components/AboutBookButton'

export default function BookCard({
  book,
  onClickDetails,
  showAlertMessage,
  runAfterOnClickFavorite
}) {
  const [showBookBtnActions, setShowBookBtnActions] = useState(false)
  const [isFavorite, setIsFavorite] = useState(true)
  const [cardHeight, setCardHeight] = useState(300)
  const [imageHeight, setImageHeight] = useState(300)

  async function fetchFavoriteBooks() {
    const data = JSON.parse(localStorage.getItem('favorites')) || []
    const id = data.find((item) => {
      return item.favoriteId === book.uuid
    })
    setIsFavorite(!!id)
  }

  const onClickFavoriteButton = async () => {
    const data = JSON.parse(localStorage.getItem('favorites')) || []
    const id = data.find((item) => {
      return item.favoriteId === book.uuid
    })
    setIsFavorite(!!id)

    if (!!id) {
      showAlertMessage?.('Livro adicionado aos favoritos', 'success')
    } else {
      showAlertMessage?.('Livro removido dos favoritos', 'success')
    }

    if (runAfterOnClickFavorite) {
      runAfterOnClickFavorite()
    }
  }

  useEffect(() => {
    fetchFavoriteBooks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setCardHeight(getCardHeight())
      setImageHeight(getImageHeight())
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getCardHeight = () => {
    return window.innerWidth <= 576 ? 160 : 210
  }

  const getImageHeight = () => {
    return window.innerWidth <= 576 ? 232 : 300
  }

  const handleAboutButtonClick = () => {
    setShowBookBtnActions(false)
    onClickDetails()
  }

  return (
    <Stack w={cardHeight} align="stretch" justify="flex-start">
      <AspectRatio style={{ height: imageHeight }}>
        <div 
          onMouseEnter={(e) => {
            e.stopPropagation();
            setShowBookBtnActions(true);
          }}
          onTouchStart={(e) => { 
            e.stopPropagation();
            setShowBookBtnActions(true);
          }}
        >
          <Image
            src={book.imageUrl}
            height={imageHeight}
            radius="lg"
          />
        </div>

        {showBookBtnActions && (
          <div
            onMouseLeave={(e) => {e.stopPropagation(); setShowBookBtnActions(false)}}
          >
            <Overlay
              blur={2}
              color="#fff"
              center
              height={232}
              radius="lg"
            >
              <Stack align="stretch" justify="flex-start" spacing="xs">
                <OpenBookButton book={book} onTouchStart={(e) => e.stopPropagation()} />
                <AboutBookButton handleClick={handleAboutButtonClick} onTouchStart={(e) => e.stopPropagation()} />
              </Stack>

              <div style={{ position: 'absolute', top: 10, right: 10 }}>
                <FavoriteButton
                  book={book}
                  isFavorite={isFavorite}
                  runAfterOnClick={onClickFavoriteButton}
                />
              </div>
            </Overlay>
        </div>
        )}
      </AspectRatio>

      <Text className={classes.title} lineClamp={2}>
        {toCamelCase(book.titleCover)}
      </Text>

      <Text className={classes.authors} lineClamp={2} mt={-6}>
        {getAuthors(book)}
      </Text>

      <Text className={classes.edition} mt={-6}>
        {getEdition(book)}
      </Text>
    </Stack>
  )
}
