import React, { useState, useEffect, useContext } from 'react'
import {
  Flex,
  Pagination,
  Loader,
  Center,
  Title,
  Button,
  Stack,
  Text
} from '@mantine/core'
import { IconHeart } from '@tabler/icons-react'

import { BooksContext } from '../../../contexts/BooksContext'
import { setScrollPostion } from '../../../utils'
import classes from './Favorites.module.css'
import BookCard from '../../../components/BookCard'
import { useBookDetails } from '../../../contexts/BookDetailsContext'

function Favorites() {
  const [page, setPage] = useState(1)
  const [books, setBooks] = useState([])
  const [pages, setPages] = useState(0)
  const [loading, setLoading] = useState(false)
  const { setBook: setSelectionBook } = useBookDetails()
  const { parseFavorites, getFavoriteBooks } = useContext(BooksContext)

  const fetchFavoriteBooks = async () => {
    setLoading(true)
    const data = await parseFavorites(page)
    setBooks(data?.realItems || [])
    setPages(data?.totalPages)
    setLoading(false)
  }

  async function handleFavorites() {
    const data = await getFavoriteBooks(2)
    localStorage.setItem('favorites', JSON.stringify(data))
  }

  const changePage = (newPage) => {
    setPage(newPage)
    setScrollPostion(0, 0)
  }

  const onClickDetails = (book) => {
    setSelectionBook(book)
  }

  useEffect(() => {
    handleFavorites()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchFavoriteBooks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <>
      <Title order={1} className={classes.title}>
        Favoritos
      </Title>

      {loading && (
        <Center maw={400} h={100} mx="auto">
          <Loader size="lg" />
        </Center>
      )}

      {!loading && books && books.length === 0 && (
        <Center>
          <Stack>
            <Center mb={20} mt={20}>
              <div className={classes.emptyResultIconContainer}>
                <IconHeart size={28} className={classes.emptyResultIcon} />
              </div>
            </Center>

            <Center>
              <Text className={classes.emptyResultTitle}>
                Sua lista de favoritos está esperando!
              </Text>
            </Center>

            <Text className={classes.emptyResultSubtitle}>
              Clique no ícone para adicionar seus livros preferidos.
            </Text>

            <Center>
              <Button
                mt={20}
                component="a"
                rel="noopener noreferrer"
                variant="white"
                color="dark"
                className={classes.emptyResultButton}
                href={`/books`}
              >
                Ver catálogo
              </Button>
            </Center>
          </Stack>
        </Center>
      )}

      {!loading && books && books.length > 0 && (
        <>
          <Flex
            mih={50}
            gap="md"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
            rowGap={30}
            columnGap={20.7}
          >
            {books.map((book) => (
              <BookCard
                key={book.uuid}
                book={book}
                runAfterOnClickFavorite={fetchFavoriteBooks}
                onClickDetails={() => onClickDetails(book)}
              />
            ))}
          </Flex>

          <Pagination
            value={page}
            total={pages}
            onChange={changePage}
            position="center"
            align="center"
            size="lg"
            className={classes.pagination}
          />
        </>
      )}
    </>
  )
}
export default Favorites
